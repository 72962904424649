import { Session } from 'next-auth';
import useSWR from 'swr';

/**
 * A hook to fetch data from authenticated api's in DC BE. Only when the accessToken is set the call will be done.
 * this prevents multiple calls when de state is still loading
 * @param fetcher The DC BE client API fetch method
 * @param options The options for the API endpoint
 * @param keys Keys on which the data needs to be fetched
 * @param sessionData the session object from DXP/Auth.
 * @returns useSWR response object
 *
 * @example
 * import { getApiLabelsByLabelPricingDynamic } from "@dc-be/client";
 * const {data, isLoading, error} = usePublicDCBE(getApiLabelsByLabelPricingDynamic, {
 *   query: {
 *     startDate: '2024-08-01',
 *     endDate: '2024-08-01',
 *     aggregation: 'hourly',
 *   }
 * })
 */

type FetchOptions = {
  path?: {
    [key: string]: string;
  };
};

export default function useAuthenticatedDCBE<T extends FetchOptions, W>(
  fetcher: (options: T) => Promise<W>,
  options: T,
  keys: string[] | null = [],
  sessionData: Session | null,
) {
  let hasPath = true;
  if (options.path) {
    Object.values(options.path).forEach(value => {
      if (!value) {
        hasPath = false;
      }
    });
  }

  const swrKey = hasPath && sessionData?.accessToken && keys && keys.length > 0 ? keys.join() : null;

  return useSWR<W>(swrKey, () => fetcher(options));
}
