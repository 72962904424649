import { FC } from 'react';

import { LoginButton } from '@components-next/auth';
import { isNextAuthEnabled } from '@dxp-auth';
import { useLinkComponent } from '@link';
import { MainMenuRightRendering } from '@sitecore/types/manual/NavigationBar';
import { NavLink } from '@sparky';
import { ProfileIcon } from '@sparky/icons';

import usePlaceholderComponentContent from './usePlaceholderComponentContent';

const LoginMenu: FC = function () {
  const Link = useLinkComponent();
  const content = usePlaceholderComponentContent<MainMenuRightRendering>('jss-main-navigation-right', 'LoginMenu');
  if (!content) return null;

  const { fields } = content;

  if (!isNextAuthEnabled) {
    return (
      <Link href={fields.selfServiceLink.value.href} linkType={fields.selfServiceLink.value.linktype}>
        <NavLink leftIcon={<ProfileIcon />}>{fields.selfServiceLink.value.text}</NavLink>
      </Link>
    );
  }

  return (
    <LoginButton
      leftIcon={<ProfileIcon />}
      label={fields.selfServiceLink.value.text}
      callbackUrl={fields.selfServiceLink.value.href}
      sendLocale={!!fields.sendLocaleToOkta?.value}
    />
  );
};

export default LoginMenu;
