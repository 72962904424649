import Cookies from 'js-cookie';
import { signIn } from 'next-auth/react';

import { useSession } from '@dxp-auth';
import { useRouter } from '@dxp-next';
import { NEXT_LOCALE_COOKIE } from '@dxp-next/constants';
import { NavLink } from '@sparky';

export const LoginButton: React.FC<Props> = ({
  leftIcon,
  label: signInLabel = 'Inloggen',
  callbackUrl,
  sendLocale = false,
}) => {
  const { data: session, status } = useSession() ?? {};
  const { basePath } = useRouter();
  let authorizationParams = {};

  if (sendLocale) {
    const locale = Cookies.get(NEXT_LOCALE_COOKIE) || 'nl';
    authorizationParams = { language: locale };
  }

  if (session || status === 'loading') {
    return null;
  }

  return (
    <NavLink
      leftIcon={leftIcon}
      onClick={() =>
        signIn(
          'okta',
          {
            callbackUrl: `${basePath}${callbackUrl}`,
          },
          authorizationParams,
        )
      }>
      {signInLabel}
    </NavLink>
  );
};

interface Props {
  leftIcon?: JSX.Element;
  label?: string;
  callbackUrl?: string;
  sendLocale?: boolean;
}
